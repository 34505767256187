<template>
  <div class="login-page">
      <div class="container h-100">
          <div class="row align-items-center h-100">
              <!-- <div class="col-4 mx-auto">
                  <img class="d-none d-xl-block" src="img/login/attribute_bg_login.png" width="1000">
              </div> -->
              <div class="col-4"></div>
              <div class="col-4">
                <div class="card text-center d-xl-block">
                    <center><img class="my-4" src="logo.png" width="150"></center>
                    <h4 class="mb-4">{{ tt('forgot_password') }}</h4>                                      
                      <div class="input-group input-group-lg style-input">
                          <div class="input-group-prepend">
                            <div class="input-group-text"><i class="fa fa-user" aria-hidden="true"></i></div>
                          </div>
                          <input id="name" type="text" class="form-control" :placeholder="tt('email')" autofocus="" autocomplete="off" v-model="email" v-on:keyup.enter="forgotPassword">
                      </div>                    
                      <p class="pt-3">
                        <button type="button" class="btn btn-sm btn-lg btn-login" style="background: linear-gradient(to left, #2e41db, #5626c7);padding-right: 40px;padding-left: 40px;color: #fff;outline-color: black;" v-on:click="forgotPassword()" :disabled="btnLogin.onLoading">
                          <span v-if="btnLogin.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                          <span v-else>{{ tt('send_password_reset_link') }}</span>
                        </button>
                      </p>                      
                      <router-link to="/login" class="py-4" tag="p"><a href="">{{ tt('back_to_login') }}</a></router-link>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
  import {mapActions} from 'vuex'
  import router from '@/routes/router'
  import Api from '@/helpers/api';
  import authService from '@/services/auth.service';

  
  export default {
    data() {
      return {        
        btnLogin: {
          onLoading: false
        },
        email: '',                 
      };
    }, 
    methods: {
      forgotPassword() {                
        let context = this;        
        if (this.email) {
          this.btnLogin.onLoading = true;

          Api(context, authService.forgotPassword(context.email)).onSuccess(function(response) {                       
              context.$notify({
                  message: response.data.message,                                 
                  type: 'success',
                  verticalAlign: 'bottom', 
                  horizontalAlign: 'left'
              });
              context.email = '';      
              context.btnLogin.onLoading = false;  
            }).onError(function(error) {
              var response = error.response;
              context.alertDialog('error', response.data.message != undefined ? response.data.message : 'The email must be a valid email address.');
              context.btnLogin.onLoading = false;
            })
            .call()
        } else {
          this.alertDialog('error', this.tt('error_email_required'));
        }
      }
    },
  }
</script>